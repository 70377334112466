<template>
  <transition name="fade-in-wrap">
    <slot></slot>
  </transition>
</template>

<script>
export default {
  name: 'FadeInTransitionWrap'
};
</script>

<style lang="scss" scoped>
.fade-in-wrap-enter-active {
  transition: all 0.8s ease-out;
}

.fade-in-wrap-leave-active {
  transition: all 0.6s ease-out;
}

.fade-in-wrap-enter,
.fade-in-wrap-leave-to {
  transform: translateY(-40px);
  opacity: 0;
}
</style>
