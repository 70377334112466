import {
  ACNE_SCAR_APPEARANCE,
  DARK_CIRCLE_COLOR,
  EYE_BAGS_TYPES,
  FACE_WRINKLES
} from '@/modules/questionnaire/api/constants';

export const FACE_WRINKLES_OPTIONS = [
  { value: FACE_WRINKLES.FEW_FACE_LINES, text: 'wrinkles.fewFaceLines' },
  { value: FACE_WRINKLES.FACE_EXPRESSION_LINES, text: 'wrinkles.faceExpressionLines' },
  { value: FACE_WRINKLES.FACE_DEEP_LINES, text: 'wrinkles.faceDeepLines' },
  { value: FACE_WRINKLES.NO, text: 'wrinkles.no' }
];

export const ACNE_SCAR_APPEARANCE_OPTIONS = [
  { value: ACNE_SCAR_APPEARANCE.DEPRESSED },
  { value: ACNE_SCAR_APPEARANCE.RAISED }
];

export const DARK_CIRCLE_COLORS_OPTIONS = [
  {
    value: DARK_CIRCLE_COLOR.BLUISH,
    text: 'darkCircleColor.bluish',
    hint: 'info.darkCircleColor.bluish'
  },
  {
    value: DARK_CIRCLE_COLOR.PIGMENTED,
    text: 'darkCircleColor.pigmented',
    hint: 'info.darkCircleColor.pigmented'
  },
  {
    value: DARK_CIRCLE_COLOR.HOLLOWED,
    text: 'darkCircleColor.hollowed',
    hint: 'info.darkCircleColor.hollowed'
  }
];

export const EYE_BAGS_TYPE_RESPONSE_OPTIONS = [
  { value: EYE_BAGS_TYPES.FLUID, text: 'eyeBags.fluid' },
  { value: EYE_BAGS_TYPES.FAT, text: 'eyeBags.fat' },
  { value: EYE_BAGS_TYPES.I_AM_NOT_SURE, text: 'eyeBags.imNotSure' }
];
