<template>
  <div class="question-divider">
    <div class="question-divider__line"></div>
    <span v-if="title" class="question-divider__title u-typography-helvetica u-text u-text--xs">
      {{ $t(title) }}
    </span>
    <div class="question-divider__line"></div>
  </div>
</template>

<script>
export default {
  name: 'QuestionDivider',
  props: {
    title: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="scss" scoped>
.question-divider {
  display: flex;
  align-items: center;

  &__line {
    flex-grow: 1;
    border-top: 1px solid var(--u-color-grey-200);
  }

  &__title {
    margin: 0 14px;
    color: var(--u-color-grey-500);
  }
}
</style>
