<template>
  <div class="q-w360 q-fit">
    <question-with-error-wrap :error="getFieldsError(['wrinkles'])">
      <single-answer-question
        id="face-wrinkles"
        title="label.wrinklesToSoften"
        :value="wrinkles"
        :options="faceWrinklesOptions"
        dysfunction="skin-appearance"
        @input="onFieldChange('wrinkles', $event)"
      />
    </question-with-error-wrap>

    <question-divider
      v-if="isQuestionDividerVisible"
      class="q-mt24 q-mb32 q-mt32-md q-mb40-md"
      title="questionnaire.tellUsMore.title"
    />

    <fade-in-transition-wrap>
      <question-with-error-wrap
        v-if="dryingSkinVisible"
        class="q-mt24 q-mt32-md"
        :error="getFieldsError(['dryingSkin'])"
      >
        <single-answer-question
          id="drying-skin"
          title="label.dryingSkin"
          :value="dryingSkin"
          :options="$options.drySkinOptions"
          hint="info.dryingSkin"
          dysfunction="skin-appearance"
          @input="onFieldChange('dryingSkin', $event)"
        />
      </question-with-error-wrap>
    </fade-in-transition-wrap>

    <fade-in-transition-wrap>
      <question-with-error-wrap
        v-if="dryOpenPoresVisible"
        class="q-mt24 q-mt32-md"
        :error="getFieldsError(['dryOpenPores'])"
      >
        <single-answer-question
          id="dry-open-pores"
          title="label.dryOpenPores"
          :value="dryOpenPores"
          :options="$options.dryOpenPoresOptions"
          hint="info.dryOpenPores"
          dysfunction="skin-appearance"
          yes-no
          @input="onFieldChange('dryOpenPores', $event)"
        />
      </question-with-error-wrap>
    </fade-in-transition-wrap>
  </div>
</template>

<script>
import QuestionDivider from '@/modules/questionnaire/components/common/QuestionDivider';
import FadeInTransitionWrap from '@/components/wrappers/FadeInTransitionWrap';
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';
import SingleAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/SingleAnswerQuestion';

import { flagAiSelectedOption } from '@/modules/questionnaire/api/helpers';
import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';

import { FACE_WRINKLES_OPTIONS } from '@/modules/questionnaire/constants/steps/skinAppearance';

import {
  AMOUNT_OPTIONS,
  YES_NO_RESPONSE_OPTIONS
} from '@/modules/questionnaire/constants/questions';

export default {
  name: 'AgeingTemplate',
  components: {
    FadeInTransitionWrap,
    QuestionDivider,
    SingleAnswerQuestion,
    QuestionWithErrorWrap
  },
  mixins: [stepTemplateMixin],
  drySkinOptions: AMOUNT_OPTIONS,
  dryOpenPoresOptions: YES_NO_RESPONSE_OPTIONS,
  props: {
    wrinkles: {
      type: String,
      required: true
    },
    wrinklesAiSelected: {
      type: String,
      default: ''
    },
    dryingSkin: {
      type: String,
      default: ''
    },
    dryingSkinVisible: {
      type: Boolean,
      default: false
    },
    dryOpenPores: {
      type: String,
      default: ''
    },
    dryOpenPoresVisible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    faceWrinklesOptions() {
      return flagAiSelectedOption(FACE_WRINKLES_OPTIONS, this.wrinklesAiSelected);
    },
    isQuestionDividerVisible() {
      return this.dryingSkinVisible || this.dryOpenPoresVisible;
    }
  }
};
</script>
